import { useStore } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'

export function useMsgDialog() {
  const store = useStore()
  const { push } = useLink()
  const judgeIsLogin = (toLogin = false, redirect = null) => {
    if (toLogin) {
      push('/login', redirect ? { redirect } : {})
    } else {
      store.commit('loginDialog/SET_DIALOGVISIBLE', true)
    }
  }
  const judgeIsCompany = () => {
    showErrorTip('', 0)
  }
  const judgeIsAuth = () => {
    showErrorTip('', 1)
  }
  const showErrorTip = (message = '', status = '3') => {
    store.commit('tipMsgDialog/SET_TIPMSGDIALOGVISIBLE', true)
    store.commit('tipMsgDialog/SET_TIPMSGSTATE', status)
    store.commit('tipMsgDialog/SET_TIPMESSAGE', message)
  }

  return {
    judgeIsLogin,
    judgeIsCompany,
    judgeIsAuth,
    showErrorTip
  }
}
