export const state = () => {
  return {
    tipMsgDialogVisible: false,
    tipMsgState: 0,
    tipMessage: '',
    // 超提
    overDelivery: 0,
    // 临时公司营业执照和授权委托书状态
    corpIdFile: {},
    // 首页弹框
    homeTipList: [],
    c2mDelivery: 0
  }
}

export const mutations = {
  SET_TIPMSGDIALOGVISIBLE(state, payload) {
    state.tipMsgDialogVisible = payload
  },
  SET_TIPMSGSTATE(state, payload) {
    state.tipMsgState = payload
  },
  SET_TIPMESSAGE(state, payload) {
    state.tipMessage = payload
  },
  SET_OVERDELIVERY(state, payload) {
    state.overDelivery = payload
  },
  // 临时公司营业执照和授权委托书状态
  SET_CORPIDFILE(state, payload) {
    state.corpIdFile = payload
  },
  SET_HOMETIPLIST(state, payload) {
    state.homeTipList = payload
  },
  SET_C2MDELIVERY(state, payload) {
    state.c2mDelivery = payload
  }
}

export const actions = {
  async GET_OVERDELIVERY({ commit }, context) {
    const { statusCode, data } = await context.$api.auth.getOverDelivery()
    if (statusCode == 200) {
      commit('SET_OVERDELIVERY', data)
    }
  },
  // 临时公司营业执照和授权委托书状态
  async GET_CORPIDFILE({ commit }, context) {
    const { statusCode, data } = await context.$api.auth.getByCorpIdFileTemp()
    if (statusCode == 200) {
      commit('SET_CORPIDFILE', data)
    }
  },
  async GET_HOMEMSG({ commit }, context) {
    const { statusCode, data } = await context.$api.auth.getDialogList()
    if (statusCode == 200) {
      commit('SET_HOMETIPLIST', data)
    }
  },
  async GET_C2MDELIVERY({ commit }, context) {
    const { statusCode, data } = await context.$api.auth.getc2mDelivery()
    if (statusCode == 200) {
      commit('SET_C2MDELIVERY', data)
    }
  }
}
