import { transformArray, renderArr } from '@/hooks/useShop'

export const state = () => {
  return {
    condition: {},
    varieties: [],
    categories: [],
    material: [],
    oiginDescs: [],
    repoDtos: [],
    deliPlaces: [],
    sortDeliPlaces: {},
    initMaterial: [],
    initRepoDtos: [],
    initOiginDescs: [],
    initDeliPlaces: [],
    measure: [],
    qualityInfo: [],
    sizeInfo: [],
    specialPrice: [
      { value: '否', key: '0' },
      { value: '是', key: '1' }
    ],
    source: [
      { value: '全部', key: '' },
      { value: '钢宝股份', key: 'A,B,C' },
      { value: '第三方', key: 'D' }
    ],
    types: [
      { value: '流通材', key: '1' },
      { value: '余材', key: '2' }
    ],
    purpose: [],
    openTime: '',
    closeTime: '',
    nowTime: '',
    cartsNumber: 0
  }
}

export const mutations = {
  SET_CONDITION(state, payload) {
    state.condition = payload
  },
  SET_VARIETIES(state, payload) {
    state.varieties = payload
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
  SET_MATERIAL(state, payload) {
    state.material = payload
  },
  SET_INITMATERIAL(state, payload) {
    state.initMaterial = payload
  },
  SET_OIGINDESCS(state, payload) {
    state.oiginDescs = payload
  },
  SET_INITOIGINDESCS(state, payload) {
    state.initOiginDescs = payload
  },
  SET_REPODTOS(state, payload) {
    state.repoDtos = payload
  },
  SET_INITREPODTOS(state, payload) {
    state.initRepoDtos = payload
  },
  SET_DELIPLACES(state, payload) {
    state.deliPlaces = payload
  },
  SET_INITDELIPLACES(state, payload) {
    state.initDeliPlaces = payload
  },
  SET_SORTDELIPLACES(state, payload) {
    state.sortDeliPlaces = payload
  },
  SET_MEASURE(state, payload) {
    state.measure = payload
  },
  SET_QUALITYINFO(state, payload) {
    state.qualityInfo = payload
  },
  SET_SIZEINFO(state, payload) {
    state.sizeInfo = payload
  },
  SET_OPENTIME(state, payload) {
    state.openTime = payload
  },
  SET_CLOSETIME(state, payload) {
    state.closeTime = payload
  },
  SET_NOWTIME(state, payload) {
    state.nowTime = payload
  },
  SET_INITSORTREPODTOS(state, payload) {
    state.initSortRepoDtos = payload
  },
  SET_CARTSNUMBER(state, payload) {
    state.cartsNumber = payload
  },
  SET_PURPOSE(state, payload) {
    state.purpose = payload
  }
}

export const actions = {
  async GET_LITEDATTR({ commit }, { context, params = {}, isInint = false }) {
    const initParams = {
      lengthEnd: '',
      lengthStart: '',
      material: '',
      origin: '',
      repositoryId: '',
      spec: '',
      thicknessEnd: '',
      thicknessStart: '',
      widthEnd: '',
      widthStart: '',
      variety: '',
      purpose: ''
    }
    const { statusCode, data } = await context.$api.goods.getSearchLists(Object.assign(initParams, params))
    if (statusCode == 200) {
      if (!data) {
        return
      }
      handleSearchData(commit, data, isInint)
    }
  },
  async GET_BIDLITEDATTR({ commit }, context) {
    const { statusCode, data } = await context.$api.goods.getBidListedAttr()
    if (statusCode == 200) {
      if (!data) {
        return
      }
      handleSearchData(commit, data, true)
    }
  },
  async GET_CARTSNUMBER({ commit }, context) {
    const { statusCode, data } = await context.$api.orderCart.getGoodsCount()
    if (statusCode == 200) {
      commit('SET_CARTSNUMBER', data)
    }
  }
}

const handleSearchData = (commit, data, isInint = false) => {
  const {
    varieties,
    materialList,
    oiginDescs,
    repositorySimpleDtos,
    deliveryPlaces,
    time,
    openTime,
    closeTime,
    dropDownAttributes: { measure, qualityInfo, sizeInfo },
    purpose
  } = data
  const varietiesArr = varieties.map((_) => {
    if (_.children && _.children.length) {
      _.children = _.children.map((child) => {
        return {
          ...child,
          value: child.name,
          key: child.id
        }
      })
    }
    return {
      ..._,
      value: _.name,
      key: _.id
    }
  })
  const repoDtosArr = repositorySimpleDtos.map((_) => {
    return {
      ..._,
      key: _.code,
      value: _.name
    }
  })
  const repoDtosRegionCode = repoDtosArr.map((_) => _.regionCode)
  const deliPlacesArr = deliveryPlaces.map((_) => {
    return {
      ..._,
      key: _.regionCode,
      value: _.regionCity
    }
  })
  const filterDeliPlace = deliPlacesArr.filter((_) => repoDtosRegionCode.includes(_.regionCode))
  const sortDeliPlacesArr = renderArr(filterDeliPlace, 'regionCityInitials')
  const deliPlacesArr1 = Object.values(sortDeliPlacesArr)?.reduce((acc, cur) => {
    acc = [...acc, ...cur]
    return acc
  }, [])
  const materialArr = materialList.map((_) => {
    return {
      ..._,
      value: _.name,
      key: _.name,
      gategoryIds: _.gategoryIds.split(','),
      pids: _.pids.split(',')
    }
  })
  let oiginDescsArr = oiginDescs.map((_) => {
    return {
      ..._,
      value: _.name,
      key: _.name
    }
  })
  oiginDescsArr = renderArr(oiginDescsArr, 'nameInitials')
  oiginDescsArr = Object.values(oiginDescsArr)?.reduce((acc, cur) => {
    acc = [...acc, ...cur]
    return acc
  }, [])
  const measureArr = transformArray(measure)
  const qualityInfoArr = transformArray(qualityInfo)
  const sizeInfoArr = transformArray(sizeInfo)
  const tempCategories = []
  varietiesArr.forEach((_) => {
    if (_.children.length) {
      _.children.forEach((res) => tempCategories.push(res))
    }
  })
  const purposeArr = purpose?.map((item) => {
    const { children = [] } = item
    children.forEach((item1) => {
      const { children = [] } = item1
      children.forEach((item2) => {
        item2.key = item2.name
        item2.value = item2.name
      })
      item1.key = item1.name
      item1.value = item1.name
      item1.children = children
    })
    return {
      ...item,
      key: item.name,
      value: item.name,
      children
    }
  })
  const condition = {
    varieties: varietiesArr,
    materialList: materialArr,
    oiginDescs: oiginDescsArr,
    repositorySimpleDtos: repoDtosArr,
    deliveryPlaces: deliPlacesArr1,
    purpose: purposeArr,
    dropDownAttributes: { measure: measureArr, qualityInfo: qualityInfoArr, sizeInfo: sizeInfoArr }
  }
  commit('SET_VARIETIES', varietiesArr)
  commit('SET_CATEGORIES', tempCategories)
  commit('SET_MATERIAL', materialArr)
  commit('SET_OIGINDESCS', oiginDescsArr)
  commit('SET_REPODTOS', repoDtosArr)
  commit('SET_DELIPLACES', deliPlacesArr1)
  commit('SET_SORTDELIPLACES', sortDeliPlacesArr)
  commit('SET_MEASURE', measureArr)
  commit('SET_QUALITYINFO', qualityInfoArr)
  commit('SET_SIZEINFO', sizeInfoArr)
  commit('SET_OPENTIME', openTime)
  commit('SET_CLOSETIME', closeTime)
  commit('SET_NOWTIME', time)
  commit('SET_CONDITION', condition)
  commit('SET_PURPOSE', purposeArr)
  if (isInint) {
    commit('SET_INITMATERIAL', materialArr)
    commit('SET_INITOIGINDESCS', oiginDescsArr)
    commit('SET_INITREPODTOS', repoDtosArr)
    commit('SET_INITDELIPLACES', deliPlacesArr1)
  }
}
