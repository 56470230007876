export default ($axios) => ({
  getGoodsLists(params) {
    return $axios.$post('/businesscenter/goodssmallsize/search', params)
  },
  getStatistics(params, cancelToken) {
    return $axios.$post('/businesscenter/goodssmallsize/statistics', params, {
      cancelToken
    })
  },
  addDemand(params) {
    return $axios.$post('/businesscenter/tecgoodssmallsizedemand/add', params)
  }
})
