import { useStore, computed } from '@nuxtjs/composition-api'
import { JIN_MAO } from '@/constant'
export function useLoginInfo() {
  const store = useStore()
  const userInfo = computed(() => store.getters.userInfo) // 用户基础信息
  const corps = computed(() => store.getters.corps) // 用户挂靠的公司
  const loginCorpId = computed(() => store.getters.corpId) // 当前选择的公司Id
  const loginCorpInfo = computed(() => corps.value.find((_) => _.corpId == loginCorpId.value)) // 当前选择的公司信息
  const loginCorpAuth = computed(() => store.getters.loginCorpAuth) // 当前选择的公司认证状态
  const permissions = computed(() => store.getters.permissions) // 当前登录公司下所拥有的权限
  const dropMenus = computed(() => store.getters.dropMenus)
  const fixedMenus = computed(() => store.getters.fixedMenus)
  const isCartAuth = computed(() => {
    const bugMenus = permissions.value?.find((_) => _.name == '买家中心') ?? []
    const cartMenu = bugMenus?.children?.findIndex((_) => _.name == '购物车') ?? -1
    return cartMenu != -1
  })
  const bidPackAuth = computed(() => {
    return getBidAuth('打包竞价')
  })
  const bidSingleAuth = computed(() => {
    return getBidAuth('单条竞价')
  })
  const isJinMaoCompany = computed(() => loginCorpInfo.value && loginCorpInfo.value.corpName == JIN_MAO)

  const getBidAuth = (authName) => {
    const bugMenus = permissions.value?.find((_) => _.name == '买家中心') ?? []
    const bidMenus = bugMenus?.children?.find((_) => _.name == '竞价管理') ?? []
    const auth = bidMenus?.children?.findIndex((_) => _.name == authName) ?? -1
    return auth != -1
  }

  const transportAuth = computed(() => {
    return getMenuAuth(permissions.value, ['买家中心', '招标管理', '运输竞价'])
  })

  const transEnquiryAuth = computed(() => {
    return getMenuAuth(permissions.value, ['买家中心', '招标管理', '运输询价'])
  })

  const c2mAuth = computed(() => {
    return getMenuAuth(permissions.value, ['买家中心', '加工管理', '询单报价'])
  })
  const getMenuAuth = (permissions, [name1, name2, name3]) => {
    const parentMenus = permissions?.find((_) => _.name == name1) ?? []
    const childMenus = parentMenus?.children?.find((_) => _.name == name2) ?? []
    const auth = childMenus?.children?.findIndex((_) => _.name == name3) ?? -1
    return auth != -1
  }
  return {
    userInfo,
    corps,
    loginCorpId,
    loginCorpInfo,
    permissions,
    dropMenus,
    fixedMenus,
    isCartAuth,
    isJinMaoCompany,
    bidPackAuth,
    bidSingleAuth,
    transportAuth,
    transEnquiryAuth,
    c2mAuth,
    loginCorpAuth
  }
}
