import { formatDate } from '@/utils/tool'
export const state = () => {
  return {
    topBanners: [],
    mainBanners: [],
    waistBanners: [],
    infoBanners: [],
    c2mBanners: [],
    msgCount: 0,
    currentTime: null
  }
}

export const mutations = {
  SET_TOPBANNERS(state, payload) {
    state.topBanners = payload
  },
  SET_MAINBANNERS(state, payload) {
    state.mainBanners = payload
  },
  SET_WAISTBANNERS(state, payload) {
    state.waistBanners = payload
  },
  SET_INFOBANNERS(state, payload) {
    state.infoBanners = payload
  },
  SET_C2MBANNERS(state, payload) {
    state.c2mBanners = payload
  },
  SET_MSGCOUNT(state, payload) {
    state.msgCount = payload
  },
  SET_CURRENTTIME(state, payload) {
    state.currentTime = payload
  }
}

export const actions = {
  async GET_ADVERTLIST({ commit }, context) {
    const { data, statusCode } = await context.$api.home.getAdvertList()
    if (statusCode == 200) {
      const topBanners = data.filter((_) => _.adType == 'AD001')
      const mainBanners = data.filter((_) => _.adType == 'AD002')
      const waistBanners = data.filter((_) => _.adType == 'AD003')
      const infoBanners = data.filter((_) => _.adType == 'AD004')
      const c2mBanners = data.filter((_) => _.adType == 'AD008')
      commit('SET_TOPBANNERS', topBanners)
      commit('SET_MAINBANNERS', mainBanners)
      commit('SET_WAISTBANNERS', waistBanners)
      commit('SET_INFOBANNERS', infoBanners)
      commit('SET_C2MBANNERS', c2mBanners)
    }
  },
  async GET_MSGCOUNT({ commit }, context) {
    const { data, statusCode } = await context.$api.home.getMsgCount()
    if (statusCode == 200) {
      commit('SET_MSGCOUNT', data)
    }
  },
  async GET_CURRENTTIME({ commit }, context) {
    const { statusCode, data } = await context.$api.bid.getSyncTime()
    if (statusCode == 200) {
      const currentTime = new Date(formatDate(new Date(data), 'yyyy/MM/dd hh:mm:ss')).getTime()
      commit('SET_CURRENTTIME', currentTime)
    }
  }
}
