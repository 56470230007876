export default ($axios) => ({
  getGoodsLists(params) {
    return $axios.$post('/businesscenter/goods/specialPrice/search', params)
  },
  getStatistics(params, cancelToken) {
    return $axios.$post('/businesscenter/goods/specialPrice/statistics', params, {
      cancelToken
    })
  },
  getRepositoryContract(params) {
    return $axios.$get('/businesscenter/goods/specialPrice/getByCode', { params })
  }
})
