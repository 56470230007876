import { PREFIXS } from '~/constant'
import { getParams } from '@/utils/sso'
export default ({ store, redirect, route, error, $config, req }) => {
  if (!store.getters.token) {
    const url = $config.mallUrl || req.headers.host
    return redirect(`${$config.ssoUrl}/login${getParams(url)}`)
  }
  // 检验是否有页面权限
  const whiteList = ['/usercenter/personInfo', '/usercenter/account', '/usercenter/joinCompany', '/wantBuy']
  let isWhite = false
  const path = route.path
  const array = store.getters.routes.concat(whiteList).map((_) => {
    if (PREFIXS.includes(_.split('/')[1])) {
      _ = '/bcs' + _
    }
    return _
  })
  for (let i = 0; i < array.length; i++) {
    if (path.startsWith(array[i])) {
      isWhite = true
      break
    }
  }
  if (isWhite == false) {
    error({
      statusCode: 403,
      message: '你没有权限访问该页面'
    })
  }
}
