export function getParams(host = '') {
  if (process.server) {
    if (!host.startsWith('http')) {
      host = process.env.NODE_ENV == 'development' ? 'http://' + host : 'https://' + host
    }
  }
  if (process.client) {
    if (host == '') {
      host = window.location.href
    }
  }
  return `?appId=EC&redirect=${encodeURIComponent(host)}`
}
