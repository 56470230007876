import { ref, onUnmounted } from '@nuxtjs/composition-api'

export function useCountDown(seconds = 60) {
  const count = ref(seconds)
  let timer = null
  const playing = ref(false)
  const countdown = () => {
    playing.value = true
    timer = setInterval(() => {
      count.value--
      if (count.value == 0) {
        clearInterval(timer)
        count.value = seconds
        playing.value = false
      }
    }, 1000)
  }
  onUnmounted(() => {
    if (timer != null) {
      clearInterval(timer)
    }
  })
  return {
    count,
    playing,
    countdown
  }
}
