export const AppFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppIntroduce = () => import('../../components/layout/Introduce.vue' /* webpackChunkName: "components/app-introduce" */).then(c => wrapFunctional(c.default || c))
export const AppPortalHeader = () => import('../../components/layout/PortalHeader.vue' /* webpackChunkName: "components/app-portal-header" */).then(c => wrapFunctional(c.default || c))
export const GbBankNumber = () => import('../../components/common/BankNumber.vue' /* webpackChunkName: "components/gb-bank-number" */).then(c => wrapFunctional(c.default || c))
export const GbCartFloatbar = () => import('../../components/common/CartFloatbar.vue' /* webpackChunkName: "components/gb-cart-floatbar" */).then(c => wrapFunctional(c.default || c))
export const GbDetailDesc = () => import('../../components/common/DetailDesc.vue' /* webpackChunkName: "components/gb-detail-desc" */).then(c => wrapFunctional(c.default || c))
export const GbEmptyList = () => import('../../components/common/EmptyList.vue' /* webpackChunkName: "components/gb-empty-list" */).then(c => wrapFunctional(c.default || c))
export const GbLoginDialog = () => import('../../components/common/LoginDialog.vue' /* webpackChunkName: "components/gb-login-dialog" */).then(c => wrapFunctional(c.default || c))
export const GbPassword = () => import('../../components/common/Password.vue' /* webpackChunkName: "components/gb-password" */).then(c => wrapFunctional(c.default || c))
export const GbPasswordNumber = () => import('../../components/common/PasswordNumber.vue' /* webpackChunkName: "components/gb-password-number" */).then(c => wrapFunctional(c.default || c))
export const GbScrollTool = () => import('../../components/common/ScrollTool.vue' /* webpackChunkName: "components/gb-scroll-tool" */).then(c => wrapFunctional(c.default || c))
export const GbSelectRegion = () => import('../../components/common/SelectRegion.vue' /* webpackChunkName: "components/gb-select-region" */).then(c => wrapFunctional(c.default || c))
export const GbSpecialOffer = () => import('../../components/common/SpecialOffer.vue' /* webpackChunkName: "components/gb-special-offer" */).then(c => wrapFunctional(c.default || c))
export const GbTimeLine = () => import('../../components/common/TimeLine.vue' /* webpackChunkName: "components/gb-time-line" */).then(c => wrapFunctional(c.default || c))
export const CardTitle = () => import('../../components/basic/CardTitle.vue' /* webpackChunkName: "components/card-title" */).then(c => wrapFunctional(c.default || c))
export const ExInputNumber = () => import('../../components/basic/ExInputNumber.vue' /* webpackChunkName: "components/ex-input-number" */).then(c => wrapFunctional(c.default || c))
export const ExSearchTable = () => import('../../components/basic/ExSearchTable.vue' /* webpackChunkName: "components/ex-search-table" */).then(c => wrapFunctional(c.default || c))
export const ExTableColumn = () => import('../../components/basic/ExTableColumn.js' /* webpackChunkName: "components/ex-table-column" */).then(c => wrapFunctional(c.default || c))
export const InputRange = () => import('../../components/basic/InputRange.vue' /* webpackChunkName: "components/input-range" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/basic/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PotocolDialog = () => import('../../components/basic/PotocolDialog.vue' /* webpackChunkName: "components/potocol-dialog" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/basic/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const SectionTitle = () => import('../../components/basic/SectionTitle.vue' /* webpackChunkName: "components/section-title" */).then(c => wrapFunctional(c.default || c))
export const SelectItem = () => import('../../components/basic/SelectItem.vue' /* webpackChunkName: "components/select-item" */).then(c => wrapFunctional(c.default || c))
export const SortButton = () => import('../../components/basic/SortButton.vue' /* webpackChunkName: "components/sort-button" */).then(c => wrapFunctional(c.default || c))
export const TabsArrow = () => import('../../components/basic/TabsArrow.vue' /* webpackChunkName: "components/tabs-arrow" */).then(c => wrapFunctional(c.default || c))
export const TipConfirm = () => import('../../components/basic/TipConfirm.vue' /* webpackChunkName: "components/tip-confirm" */).then(c => wrapFunctional(c.default || c))
export const ContentTooltip = () => import('../../components/basic/contentTooltip.vue' /* webpackChunkName: "components/content-tooltip" */).then(c => wrapFunctional(c.default || c))
export const DbButton = () => import('../../components/basic/dbButton.vue' /* webpackChunkName: "components/db-button" */).then(c => wrapFunctional(c.default || c))
export const ImageBox = () => import('../../components/basic/imageBox.vue' /* webpackChunkName: "components/image-box" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/basic/tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
