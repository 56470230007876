import qs from 'qs'
import Message from '@/utils/message'
import { setAuthInfo, removeAuthInfo } from '@/utils/auth'
import { blobToObj } from '@/utils/tool'
import { getParams } from '@/utils/sso'
export default function (context) {
  const { $axios, $cookies, store, redirect, $config } = context
  $axios.defaults.timeout = 20000
  $axios.onRequest((config) => {
    if (config.method == 'GET') {
      config.params = qs.stringify(config.params)
    }
    if (config.method.toLocaleLowerCase() == 'delete') {
      config.data = config.params
    }
    const token = $cookies.get('token') || ''
    if (!!config.headers.Authorization == false) {
      config.headers.Authorization = token
    }
    return config
  })

  let isRefreshing = false
  let requests = []

  $axios.onResponse(async (response) => {
    const { config } = response
    if (config.responseType == 'blob') {
      if (response.data.type == 'application/json') {
        try {
          response.data = await blobToObj(response.data)
        } catch (error) {
          return Promise.reject(error)
        }
      } else {
        return response
      }
    }
    const { statusCode, message } = response?.data
    if (statusCode == 11006) {
      // token失效
      if (isRefreshing == false) {
        isRefreshing = true
        return store
          .dispatch('auth/REFRESH_TOKEN', context)
          .then((res) => {
            setAuthInfo(context, {
              token: res,
              refreshToken: $cookies.get('refreshToken'),
              userId: $cookies.get('userId')
            })
            config.headers.Authorization = res
            requests.forEach((cb) => cb(res))
            requests = []

            return $axios(config)
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            isRefreshing = false
          })
      } else {
        return new Promise((resolve) => {
          requests.push((token) => {
            config.headers.Authorization = token
            resolve($axios(config))
          })
        })
      }
    } else if (statusCode == 11007 || statusCode == 11008) {
      if (process.client) {
        Message.error({
          message: '登录信息已过期,请重新登录',
          onClose: () => {
            removeAuthInfo(context)
            location.href = `${$config.ssoUrl}/login${getParams()}`
          }
        })
      } else {
        removeAuthInfo(context)
        redirect('/')
      }
    } else if (statusCode == 11009) {
      if (process.client) {
        Message.error({
          message: '账户权限发生变化,请重新登录',
          onClose: () => {
            removeAuthInfo(context)
            location.href = `${$config.ssoUrl}/login${getParams()}`
          }
        })
      } else {
        removeAuthInfo(context)
        redirect('/')
      }
    } else if (statusCode == 11005) {
      removeAuthInfo(context)
      redirect('/')
    } else if (statusCode == 200) {
      // 操作成功
      return response
    } else {
      if (config?.showMessage != false) {
        Message.error(message)
      }
      return response
    }
  })

  $axios.onError((error) => {
    if (error.message?.includes('timeout')) {
      Message.error('请求超时，请重新尝试')
    } else if (error.response) {
      const { status: code, statusText, data } = error.response
      if (/^[4|5]/.test(code)) {
        Promise.reject(statusText)
      } else {
        Message.error(data.message)
      }
    }
  })
}
