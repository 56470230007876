import { useRouter, useContext } from '@nuxtjs/composition-api'
import { PREFIXS } from '~/constant'
import { getParams } from '~/utils/sso'
import { encodeSearchParams } from '@/utils/tool'

export function useLink() {
  const router = useRouter()
  const { $config } = useContext()
  const push = (path, query) => {
    const prefix = path.split('/')[1]
    if (PREFIXS.includes(prefix)) {
      path = '/bcs' + path
      window.location.href = path
    } else if (path == '/login') {
      const prefix0 = query.redirect?.split('/')[1]
      const host = `${window.location.protocol}//${window.location.host}`
      let url = ''
      if (PREFIXS.includes(prefix0)) {
        url = host + '/bcs' + query.redirect
      } else if (query.redirect) {
        url = host + query.redirect
      } else {
        url = window.location.href
      }
      window.location.href = `${$config.ssoUrl}/login${getParams(url)}`
    } else {
      router.push({ path, query })
    }
  }
  const back = () => {
    router.go(-1)
  }
  const pushBcs = (path, isBcs = true) => {
    window.open(isBcs ? '/bcs' + path : path, '_blank')
  }
  const replace = (path, query) => {
    const prefix = path.split('/')[1]
    if (PREFIXS.includes(prefix)) {
      path = '/bcs' + path
    }
    router.replace({ path, query }).catch(() => {
      console.log('router.replace-error')
    })
  }

  const pushQuery = (path, query) => {
    history.pushState({}, null, `${path}?${encodeSearchParams(query)}`)
  }
  return {
    push,
    back,
    pushBcs,
    replace,
    pushQuery
  }
}
