// 商城-招标专区-运输招标
export default ($axios) => ({
  // 竞价时间段
  getTransBidParam() {
    return $axios.$get('/businesscenter/enquiryBid/buyer/getEnquiryBidParam')
  },
  // 竞价列表
  getTransBidList(params) {
    return $axios.$get('/businesscenter/enquiryBid/buyer/getEnquiryBidList', { params })
  },
  // 竞价详情信息
  getTransBidDetail(params) {
    return $axios.$get('/businesscenter/enquiryBid/buyer/getEnquiryBidDetail', { params })
  },
  // 标的物数据
  getTransportDetail(params) {
    return $axios.$get('/businesscenter/enquiryBid/buyer/getEnquiryGoodsDetail', { params })
  },
  joinAuction(param) {
    return $axios.$post('/businesscenter/bid/buyerCenterEnquiry/joinAuction', param, { showMessage: false })
  }
})
