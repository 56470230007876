export const state = () => {
  return {
    token: '',
    refreshToken: ''
  }
}

export const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload
  },
  SET_REFRESH_TOKEN(state, payload) {
    state.refreshToken = payload
  }
}

export const actions = {
  async REFRESH_TOKEN({ commit }, { $api, $cookies }) {
    const { statusCode, data } = await $api.auth.refreshToken({
      refreshToken: $cookies.get('refreshToken'),
      optUserId: $cookies.get('userId')
    })
    if (statusCode == 200) {
      return new Promise((resolve) => resolve(data))
    }
    return new Promise((resolve, reject) => reject(new Error('error')))
  }
}
