export const state = () => {
  return {
    dialogVisible: false
  }
}

export const mutations = {
  SET_DIALOGVISIBLE(state, payload) {
    state.dialogVisible = payload
  }
}

export const actions = {}
