import math from '@/utils/math'

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function formatDate(date, fmt = 'yyyy-MM-dd hh:mm:ss') {
  if (!!date == false) {
    return ''
  }
  date = date.toString().replace(/-+/g, '/')
  const newDate = new Date(date)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (newDate.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': newDate.getMonth() + 1,
    'd+': newDate.getDate(),
    'h+': newDate.getHours(),
    'm+': newDate.getMinutes(),
    's+': newDate.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

export function formatNumber(data, decimals = 0, decPoint, thousandsSep) {
  if (data == null || data == undefined) {
    return ''
  }
  if (!decimals) {
    const parts = data.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  } else {
    /*
     * 参数说明：
     * data：要格式化的数字
     * decimals：保留几位小数
     * decPoint：小数点符号
     * thousandsSep：千分位符号
     * */
    data = (data + '').replace(/[^0-9+-Ee.]/g, '')
    const n = !isFinite(+data) ? 0 : +data
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
    const dec = typeof decPoint === 'undefined' ? '.' : decPoint
    const s = n.toString().split('.')
    const re = /(-?\d+)(\d{3})/
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, '$1' + sep + '$2')
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    } else {
      s[1] = s[1].substring(0, prec) // 小数点位数超出长度时截取前面的位数
    }
    return s.join(dec)
  }
}

export const formatMoney = (data) => {
  if (data == null || data == undefined) {
    return '-'
  }
  return formatNumber(toFixed(parseFloat(data)))
}

export function toFixed(number, decimals = 2) {
  return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals).toFixed(decimals)
}

export function formatTime(time, option) {
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return formatDate(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

export function filterPhone(value) {
  if (!value) return ''
  let str = value
  str = str.toString().replace(/^(\d{3})(\d{4})(\d{4})/g, '$1****$3')
  return str
}

/**
 * 小数相加，decimals最大小数位
 */
export const mathAdd = (arg1, arg2) => {
  return math.add(arg1, arg2)
}

/**
 * 小数相减，decimals最大小数位
 */
export const mathSub = (arg1, arg2) => {
  return math.subtract(arg1, arg2)
}

export const mathMul = (arg1, arg2) => {
  return math.multiply(arg1, arg2)
}

/**
 * 除法运算，避免数据相除小数点后产生多位数和计算精度损失。
 * @param num1 被除数
 * @param num2 除数
 */
export const mathDiv = (arg1, arg2) => {
  return math.divide(arg1, arg2)
}

// 日期加天
export function addDays(dateStr, days = 1) {
  if (dateStr == '') {
    return ''
  }
  const date = new Date(dateStr)
  date.setDate(date.getDate() + days)
  return formatDate(date, 'yyyy-MM-dd')
}

export const blobToObj = (blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      const { result } = e.target
      try {
        resolve(JSON.parse(result))
      } catch (error) {
        reject(error)
      }
    }
    fileReader.readAsText(blob)
  })
}

export const downloadFileByUrl = (url, fileName) => {
  // 创建a标签，用于跳转至下载链接
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', decodeURI(fileName))
  // 兼容：某些浏览器不支持HTML5的download属性
  if (typeof link.download === 'undefined') {
    link.setAttribute('target', '_blank')
  }
  // 挂载a标签
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 节流
export function throttle(fn, time) {
  let timer = null
  time = time || 1000
  return function (...args) {
    if (timer) {
      return
    }
    const _this = this
    timer = setTimeout(() => {
      timer = null
    }, time)
    fn.apply(_this, args)
  }
}

// Sleep
export function sleep(millSeconds = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, millSeconds)
  })
}

export function encodeSearchParams(obj) {
  const params = []

  Object.keys(obj).forEach((key) => {
    let value = obj[key]
    // 如果值为undefined我们将其置空
    if (typeof value === 'undefined') {
      value = ''
    }
    // 对于需要编码的文本（比如说中文）我们要进行编码
    params.push([key, encodeURIComponent(value)].join('='))
  })

  return params.join('&')
}
