/**
 * 手机
 */
export const phoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
/**
 * 座机
 */
const telReg = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/

/** 数字 */
const numberReg = /^(\d+)$/

/** 数字 */
export const numberRegSix = /^\d{6}$/

/** 用户名 */
const userNameReg = /^[0-9A-Za-z]{2,50}$/

export const integerReg = /^\+?[1-9][0-9]*$/

function required(data = '', trigger = 'blur') {
  return {
    required: true,
    trigger,
    message: data
  }
}
/**
 * 6位纯数字
 * 支付密码
 */
export const numSix = (msg = '请输入支付密码') => [
  required(msg),
  {
    pattern: numberRegSix,
    message: '只能6位纯数字',
    trigger: 'blur'
  }
]

/**
 * 用户名
 */
export const userName = () => [
  required('用户名不能为空'),
  {
    pattern: userNameReg,
    message: '只能包含数字或字母,长度2-50位',
    trigger: 'blur'
  }
]

/**
 * 密码
 */
export const password = () => [
  required('请输入密码'),
  {
    min: 6,
    message: `密码长度不小于6位`,
    trigger: 'blur'
  }
]

/**
 * 手机
 */
export const phone = (msg = '请输入手机号') => [
  required(msg),
  {
    trigger: 'change',
    validator: (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error(msg))
      } else if (!value.startsWith('1')) {
        callback(new Error(`手机号格式错误`))
      } else if (value.length > 11 && !phoneReg.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
  },
  {
    pattern: phoneReg,
    message: '手机号格式不正确',
    trigger: 'blur'
  }
]

/**
 * 邮箱
 */
export const mail = () => [required('邮箱不能为空', 'change'), { type: 'email', message: '邮箱格式不正确', trigger: 'change' }]

/**
 * 必填
 */
export const requiredInput = (data, trigger) => [required(data, trigger)]

/**
 * 数字
 */
export const number = (type = '价格', msg = '请输入正确的数字格式') => [
  required(`${type}不能为空`),
  {
    type: 'number',
    trigger: 'change',
    message: msg,
    transform(value) {
      return Number(value)
    }
  }
]

/**
 * 字符范围<最小，最大>
 */
export const reason = (min = 10, max = 250, trigger = 'blur') => [
  { required: true, message: `请输入至少${min}个字符`, trigger },
  { min, message: `请输入至少${min}个字符`, trigger: 'blur' },
  { max, message: `字符不能超过${max}个`, trigger: 'blur' }
]

/**
 * 字数范围<最大>
 */
export const max = (name, max = 50) => [
  { required: true, message: `${name}不能为空`, trigger: 'blur' },
  { max, message: `${name}字符不能超过${max}个`, trigger: 'change' }
]

export const array = (name) => [
  {
    required: true,
    trigger: 'change',
    validator: (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error(`${name}`))
      } else {
        callback()
      }
    }
  }
]

/**
 * 手机或电话
 */
export const phoneOrTel = () => [
  required('请输入手机或电话号码'),
  {
    required: '请输入手机或电话号码',
    trigger: 'change',
    validator: (rule, value, callback) => {
      if (telReg.test(value) || phoneReg.test(value)) {
        callback()
      } else {
        callback(new Error('请确认号码是否正确'))
      }
    }
  }
]

export const checkPwdStrong = (pwd) => {
  /* 纯数字 */
  const reg1 = /^[0-9]*$/
  /* 纯字母 */
  const reg2 = /^[A-Za-z]+$/
  /* 同时包含数字、大写、小写字母 */
  const reg3 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,20}$/
  if (reg1.test(pwd) || reg2.test(pwd)) {
    return 0
  } else if (reg3.test(pwd)) {
    return 2
  } else {
    return 1
  }
}
