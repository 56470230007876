export default ($axios) => ({
  // 服务器时间
  getSyncTime() {
    return $axios.$get('/businesscenter/common/syncTime')
  },
  getPackageBidList(params) {
    return $axios.$get('/businesscenter/bid/buyer/getPackageBidList', { params })
  },
  getPackageBidDetail(params) {
    return $axios.$get('/businesscenter/bid/buyer/getPackageBidDetail', { params })
  },
  getPackageBidGoods(params) {
    return $axios.$get('/businesscenter/bid/buyer/getPackageBidGoods', { params })
  },
  exportPackageBidGoods(params) {
    return $axios.$get('/businesscenter/bid/buyer/exportPackageBidGoods', { params, responseType: 'blob' })
  },
  getPackageBidParam() {
    return $axios.$get('/businesscenter/bid/buyer/getPackageBidParam')
  },
  getSingleBidParam() {
    return $axios.$get('/businesscenter/bid/buyer/getSingleBidParam')
  },
  getSingleBidList(param) {
    return $axios.$post('/businesscenter/bid/buyer/getSingleBidList', param)
  },
  getSingleBidGoodsDetail(params) {
    return $axios.$get('/businesscenter/bid/buyer/getSingleBidGoodsDetail', { params })
  },
  joinAuction(param) {
    return $axios.$post('/businesscenter/bid/buyerCenter/joinAuction', param, { showMessage: false })
  },
  getValidateBid(params) {
    return $axios.$get('/businesscenter/bid/buyerCenter/validateBid', { params, showMessage: false })
  },
  joinBatchSingle(param) {
    return $axios.$post('/businesscenter/bid/buyerCenter/batchJoinSingleAuction', param, { showMessage: false })
  }
})
