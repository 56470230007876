// 商城-招标专区-运输招标
export default ($axios) => ({
  // 竞价时间段
  getTransBidParam() {
    return $axios.$get('/businesscenter/transBid/buyer/getTransBidParam')
  },
  // 竞价列表
  getTransBidList(params) {
    return $axios.$get('/businesscenter/transBid/buyer/getTransBidList', { params })
  },
  // 竞价详情信息
  getTransBidDetail(params) {
    return $axios.$get('/businesscenter/transBid/buyer/getTransBidDetail', { params })
  },
  getTransportDetail(params) {
    return $axios.$get('/businesscenter/transBid/buyer/getTransportGoodsDetail', { params })
  }
})
