import { create, all } from 'mathjs'

const { format, bignumber, add, subtract, multiply, divide, round } = create(all, { number: 'BigNumber' })

export const math = (type = '+', ...rest) => {
  const decimal = 3
  let func = add
  switch (type) {
    case '+':
      func = add
      break
    case '-':
      func = subtract
      break
    case '*':
      func = multiply
      break
    case '/':
      func = divide
      break
  }
  return rest.reduce((acc, cur) => round(+format(func(bignumber(acc), bignumber(cur))), decimal))
}
