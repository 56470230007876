import { constantMenus } from '@/utils/menu'
import { flattenTree } from '~/utils'
import { JIN_MAO } from '@/constant'
export const state = () => {
  return {
    userInfo: '',
    corpId: '',
    corps: [],
    permissions: [],
    buttons: [],
    routes: [],
    title: '',
    dropMenus: [],
    fixedMenus: [
      ...constantMenus.map((menu) => {
        return { ...menu }
      })
    ],
    transactionParams: {},
    homeTreeData: [],
    corpChangeFlag: false,
    highlightPath: '',
    loginCorpAuth: false
  }
}

export const mutations = {
  SET_USERINFO(state, payload) {
    state.userInfo = payload
  },
  SET_CORPS(state, payload) {
    state.corps = payload
  },
  SET_CORPID(state, payload) {
    state.corpId = payload
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload
  },
  SET_ROUTES(state, payload) {
    state.routes = payload
  },
  SET_BUTTONS(state, payload) {
    state.buttons = payload
  },
  SET_TITLE(state, payload) {
    state.title = payload
  },
  SET_DROPMENUS(state, payload) {
    state.dropMenus = payload
  },
  SET_FIXEDMENUS(state, payload) {
    state.fixedMenus = payload
  },
  SET_TRANSACTIONPARAMS(state, payload) {
    state.transactionParams = payload
  },
  SET_HOMETREEDATA(state, payload) {
    state.homeTreeData = payload
  },
  SET_CORPCHANGEFLAG(state, payload) {
    state.corpChangeFlag = payload
  },
  SET_HIGHLIGHTPATH(state, payload) {
    state.highlightPath = payload
  },
  SET_CORPAUTH(state, payload) {
    state.loginCorpAuth = payload
  }
}

export const actions = {
  async GET_USERINFO({ commit }, context) {
    const { statusCode, data } = await context.$api.auth.getUserInfo()
    if (statusCode == 200) {
      commit('SET_USERINFO', data)
      commit('SET_CORPS', data?.tecCorpOptEntityList || [])
    }
  },
  async GET_PERMISSION({ commit, state }, context) {
    const { data } = await context.$api.auth.getPermission({
      optUserId: state.userInfo.optUserId,
      corpId: state.corpId
    })
    commit('SET_PERMISSIONS', data)
    const tree = flattenTree(data)
    const routes =
      tree
        ?.filter((_) => _.type == 1)
        .map((_) => {
          return `${_.component}${_.router}`
        }) || []
    const buttons = tree?.filter((_) => _.type == 2).map((_) => _.permission)
    commit('SET_ROUTES', routes)
    commit('SET_BUTTONS', buttons)
    commit('SET_DROPMENUS', renderDropMenus(data))
    commit('SET_FIXEDMENUS', getFixedMenusAuth(renderDropMenus(data), state.userInfo))
  },
  // 获取后台设置的交易参数
  async GET_TRANSACTIONPARAMS({ commit }, context) {
    const { statusCode, data } = await context.$api.transactionParams.getTransactionParams()
    if (statusCode == 200) {
      commit('SET_TRANSACTIONPARAMS', data)
    }
  },
  // 获取首页分类树
  async GET_CATEGORYLIST({ commit }, context) {
    const { statusCode, data } = await context.$api.goodsType.getCategoryList()
    if (statusCode == 200) {
      commit('SET_HOMETREEDATA', data)
    }
  },
  // 获取当前公司认证状态
  async GET_CORPAUTH({ commit }, context) {
    const { data, statusCode } = await context.$api.company.getCorpAuth()
    if (statusCode == 200) {
      const { authStatus, auditStatus } = data
      const auth = authStatus == 4 && auditStatus == 2
      commit('SET_CORPAUTH', auth)
    }
  }
}

const renderDropMenus = (permissions) => {
  const menus = []
  const per = permissions || []
  for (const i of per) {
    if (i.children.length) {
      const list = {}
      list.name = i.name
      list.children = []
      for (const child1 of i.children) {
        if (child1.children.length) {
          const list1 = {}
          list1.name = child1.name
          list1.path = `${child1.children[0].component}${child1.children[0].router}`
          list1.children = []
          list.children.push(list1)
          for (const child2 of child1.children) {
            const list2 = {}
            list2.name = child2.name
            list2.path = `${child2.component}${child2.router}`
            list1.children.push(list2)
          }
        }
      }
      menus.push(list)
    }
  }
  return menus
}

const getFixedMenusAuth = (perMenus, userInfo) => {
  const FixedMenus = [
    ...constantMenus.map((menu) => {
      return { ...menu }
    })
  ]

  const filterPerMenus = perMenus.filter((_) => _.name != '账号中心')
  FixedMenus.forEach((_cMenu) => {
    // dropMenus
    const _cMenuChildren = _cMenu.children ?? []
    const _dropMenusChildren = filterPerMenus.find((_) => _.name == _cMenu.name)?.children || []
    _cMenu.childrenPath = []
    _cMenuChildren.forEach((_cmChild) => {
      _cmChild.isAuth = false
      _cmChild.childrenPaths = []
      if (_cMenu.name == '卖家中心' && _cmChild.name == '招标管理') {
        if (userInfo.corpName == JIN_MAO && (userInfo.primaryUser == 1 || (userInfo.primaryUser == 0 && transportAuth(perMenus)))) {
          _cmChild.isHidden = false
        } else {
          _cmChild.isHidden = true
        }
      }
      if (_cMenu.name == '卖家中心' && _cmChild.name == '加工管理') {
        _cmChild.isHidden = userInfo.corpName != JIN_MAO
      }
      if (_cMenu.name == '买家中心' && _cmChild.name == '加工管理') {
        _cmChild.isHidden = userInfo.corpName == JIN_MAO
      }
      if (_cMenu.name == '卖家中心' && _cmChild.name == '数据中心') {
        if (userInfo.corpName == JIN_MAO && dataAnalysisAuth(perMenus)) {
          _cmChild.isHidden = false
        } else {
          _cmChild.isHidden = true
        }
      }
      if (_dropMenusChildren.length) {
        if (_cMenu.name != '资金中心' && _cMenu.name != '加工中心') {
          const sameObj = _dropMenusChildren.find((_) => _.name == _cmChild.name)
          if (sameObj) {
            _cmChild.path = sameObj.path
            _cmChild.isAuth = true
            _cmChild.childrenPaths = sameObj.children.map((_) => _.path)
            _cMenu.childrenPath = [..._cMenu.childrenPath, ..._cmChild.childrenPaths]
          }
        } else {
          const sameObj = _dropMenusChildren[0].children?.find((_) => _.name == _cmChild.name)
          if (sameObj) {
            _cmChild.path = sameObj.path
            _cmChild.isAuth = true
            _cmChild.childrenPaths = [sameObj.path]
            _cMenu.childrenPath = [..._cMenu.childrenPath, ..._cmChild.childrenPaths]
          }
        }
      }
    })

    if (_cMenu.childrenPath.length > 0) {
      _cMenu.path = _cMenu.childrenPath[0]
      _cMenu.isAuth = true
    } else {
      _cMenu.path = ''
      _cMenu.isAuth = false
    }
  })
  return FixedMenus
}

const transportAuth = (permissions) => {
  return getMenuAuth(permissions, ['卖家中心', '招标管理', '运输竞价']) || getMenuAuth(permissions, ['卖家中心', '招标管理', '运输询价'])
}

const dataAnalysisAuth = (permissions) => {
  return getMenuAuth(permissions, ['卖家中心', '数据中心', '数据分析'])
}

const getMenuAuth = (permissions, [name1, name2, name3]) => {
  const sellerMenus = permissions?.find((_) => _.name == name1) ?? []
  const childMenus = sellerMenus?.children?.find((_) => _.name == name2) ?? []
  const auth = childMenus?.children?.findIndex((_) => _.name == name3) ?? -1
  return auth != -1
}
