export default ($axios) => ({
  createDemandInfo(params) {
    return $axios.$post('/businesscenter/demand/buyer/createDemandInfo', params)
  },
  uploadFile(params) {
    return $axios.$post('/businesscenter/cloudStorage/uploads', params)
  },
  getC2MBuyerContract(params) {
    return $axios.$get('/businesscenter/tpcprocessordercontract/displayClientContract', { params })
  },
  getSaleBuyerContract(params) {
    return $axios.$get('/businesscenter/tpcprocessordercontract/displayBuySellContract', { params })
  },
  getC2MFactoryContract(params) {
    return $axios.$get('/businesscenter/tpcprocessordercontract/displayFactoryClientContract', { params })
  },
  getSaleFactoryContract(params) {
    return $axios.$get('/businesscenter/tpcprocessordercontract/displayFactorySellContract', { params })
  }
})
