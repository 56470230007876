import Vue from 'vue'
// import VChart from 'vue-echarts'
// import { use } from 'echarts/core'
// import { LineChart, BarChart } from 'echarts/charts'
// import { GridComponent, TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
// import { SVGRenderer } from 'echarts/renderers'

// use([LineChart, BarChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent, SVGRenderer])

import VChart from 'vue-echarts/components/ECharts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/grid'
import 'echarts/lib/chart/lines'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/title'
import 'echarts/lib/component/tooltip'
Vue.component('VChart', VChart)
