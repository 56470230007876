export default ($axios) => ({
  getOriginList() {
    return $axios.$get('/businesscenter/msg/searchOrgSum')
  },
  getRecommendList() {
    return $axios.$get('/businesscenter/msg/searchRecommend')
  },
  getNewsList(params) {
    return $axios.$get('/businesscenter/tecnews/search', { params })
  },
  getNewsDeatil(params) {
    return $axios.$get('/businesscenter/tecnews/getByPk', { params, showMessage: false })
  },
  getNoticeList(params) {
    return $axios.$get('/businesscenter/tecplacard/search', { params })
  },
  getNoticeDeatil(params) {
    return $axios.$get('/businesscenter/tecplacard/getByPk', { params, showMessage: false })
  },
  getShopAdvertList() {
    return $axios.$get('/businesscenter/shop/search')
  },
  getAdvertList() {
    return $axios.$get('/businesscenter/tecad/searchCache')
  },
  getSumWeight() {
    return $axios.$get('/businesscenter/msg/sumOrderWeight')
  },
  // getOrdWeight() {
  //   return $axios.$get('/businesscenter/msg/searchOrdWeight')
  // },
  getOrdWeightLastMonth() {
    return $axios.$get('/businesscenter/msg/searchOrdWeightLastMonth')
  },
  getExPrice() {
    return $axios.$get('/businesscenter/msg/searchExPrice')
  },
  addFeedback(param) {
    return $axios.$post('/businesscenter/tecfeedback/add', param)
  },
  getMessageList(params) {
    return $axios.$get('/businesscenter/msg/search', { params })
  },
  getBidPackList() {
    return $axios.$get('/businesscenter/bid/buyer/getMainPackageBidList')
  },
  getBidSingleList() {
    return $axios.$get('/businesscenter/bid/buyer/getMainSingleBidList')
  },
  getMsgCount() {
    return $axios.$get('/businesscenter/msg/countMsg')
  },
  // 已读
  readed(params) {
    return $axios.$get('/businesscenter/msg/readed', { params })
  },
  // 全部已读
  readedAll() {
    return $axios.$get('/businesscenter/msg/readedAll')
  },
  getNewsTypeList() {
    return $axios.$get('/businesscenter/tecnews/getEcNewsTypeList')
  },
  // 帮助中心模板
  dowmloadTemp() {
    return $axios.$get('/businesscenter/public/file/sellContract', { responseType: 'blob' })
  }
})
