import Vue from 'vue'
export default function ({ store }) {
  Vue.directive('has', {
    inserted(el, binding) {
      if (!store.getters.buttons.includes(binding.arg)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  })
}
