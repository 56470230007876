import Vue from 'vue'

export default function () {
  // 防止重复提交指令
  Vue.directive('preventReClick', {
    inserted(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.classList.add('is-disabled')
          el.disabled = true
          setTimeout(() => {
            if (!el.attributes.disabled?.value) {
              el.disabled = false
              el.classList.remove('is-disabled')
            }
          }, binding.value || 1000)
        }
      })
    }
  })
}
