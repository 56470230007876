export default ($axios) => ({
  addCart(param) {
    return $axios.$post('/businesscenter/orderCart/add', param, { showMessage: false })
  },
  addBatchCart(param) {
    return $axios.$post('/businesscenter/orderCart/batchAdd', param, { showMessage: false })
  },
  getGoodsCount() {
    return $axios.$get('/businesscenter/orderCart/getGoodsCount')
  },
  getOrderCartList() {
    return $axios.$get('/businesscenter/orderCart/search', { showMessage: false })
  },
  updateCart(param) {
    return $axios.$post('/businesscenter/orderCart/updateCart', param, { showMessage: false })
  },
  delAllGoods() {
    return $axios.$delete('/businesscenter/orderCart/delAllGoods', { showMessage: false })
  },
  delInvalidGoods() {
    return $axios.$delete('/businesscenter/orderCart/delInvalidGoods', { showMessage: false })
  },
  delGoods(params) {
    return $axios.$delete('/businesscenter/orderCart/delGoods', { params }, { showMessage: false })
  },
  createOrderValidatePage(param) {
    return $axios.$post('/businesscenter/orderCart/createOrderValidatePage', param, { showMessage: false })
  },
  validateBuyNow(param) {
    return $axios.$post('/businesscenter/orderCart/validateBuyNow', param, { showMessage: false })
  },
  displayContract(type, param) {
    return $axios.$post(`/businesscenter/contract/displayContract?type=${type}`, param, { showMessage: false })
  },
  signContract(param) {
    return $axios.$post('/businesscenter/contract/sign', param, { timeout: 20 * 1000, showMessage: false })
  },
  retrySign(param) {
    return $axios.$post('/businesscenter/contract/retrySign', param, { showMessage: false })
  },
  getSecurity(params) {
    return $axios.$get('/businesscenter/account/security', { params })
  },
  getCartExport() {
    return $axios.$get('/businesscenter/orderCart/export', { responseType: 'blob' })
  }
})
