import auth from '@/api/auth'
import sms from '@/api/sms'
import region from '@/api/region'
import company from '@/api/company'
import account from '@/api/account'
import goodsType from '@/api/goodsType'
import goods from '@/api/goods'
import transactionParams from '@/api/transactionParams'
import orderCart from '@/api/orderCart'
import pay from '@/api/pay'
import bid from '@/api/bid'
import transport from '@/api/transport'
import home from '@/api/home'
import potocol from '@/api/potocol'
import wantBuy from '@/api/wantBuy'
import c2m from '@/api/c2m'
import questionaire from '@/api/questionaire'
import contract from '@/api/contract'
import smallSize from '@/api/smallSize'
import specialPrice from '@/api/specialPrice'
import transportEnquiry from '@/api/transportEnquiry'

export default ({ $axios }, inject) => {
  const api = {
    auth: auth($axios),
    sms: sms($axios),
    region: region($axios),
    company: company($axios),
    account: account($axios),
    goodsType: goodsType($axios),
    goods: goods($axios),
    transactionParams: transactionParams($axios),
    orderCart: orderCart($axios),
    pay: pay($axios),
    bid: bid($axios),
    transport: transport($axios),
    home: home($axios),
    potocol: potocol($axios),
    wantBuy: wantBuy($axios),
    c2m: c2m($axios),
    questionaire: questionaire($axios),
    contract: contract($axios),
    smallSize: smallSize($axios),
    specialPrice: specialPrice($axios),
    transportEnquiry: transportEnquiry($axios)
  }
  inject('api', api)
}
