function getDomian(host) {
  if (process.env.NODE_ENV == 'development') {
    return 'localhost'
  }
  return host.split('.').slice(-2).join('.')
}
/**
 * 设置登录验证信息
 * @param {object} ctx - 服务端传入context 客户端传入this
 * @param {object} res - 登录验证信息
 */
function setAuthInfo(ctx, res) {
  let $cookies, $store
  // 客户端
  if (process.client) {
    $cookies = ctx.$cookies
    $store = ctx.store
  }
  // 服务端
  if (process.server) {
    $cookies = ctx.app.$cookies
    $store = ctx.store
  }
  if ($cookies && $store) {
    const hours = 6
    const expires = new Date(new Date().setHours(new Date().getHours() + hours))
    const domain = getDomian(ctx.$config.mallUrl)
    $cookies.set('token', res.token, { expires, path: '/', secure: true, sameSite: 'None', domain })
    $cookies.set('refreshToken', res.refreshToken, { expires, path: '/', secure: true, sameSite: 'None', domain })
    $cookies.set('userId', res.userId, { expires, path: '/', secure: true, sameSite: 'None', domain })
    $store.commit('auth/SET_TOKEN', res.token)
    $store.commit('auth/SET_REFRESH_TOKEN', res.refreshToken)
  }
}
/**
 * 移除登录验证信息
 * @param {object} ctx - 服务端传入context 客户端传入this
 */
function removeAuthInfo(ctx) {
  let $cookies, $store
  // 客户端
  if (process.client) {
    $cookies = ctx.$cookies
    $store = ctx.store
  }
  // 服务端
  if (process.server) {
    $cookies = ctx.app.$cookies
    $store = ctx.store
  }
  if ($cookies && $store) {
    const domain = getDomian(ctx.$config.mallUrl)
    $cookies.removeAll({ path: '/', domain })
    $cookies.removeAll()
    $store.commit('auth/SET_TOKEN', '')
    $store.commit('auth/SET_REFRESH_TOKEN', '')
    $store.commit('app/SET_CORPID', '')
    $store.commit('app/SET_USERINFO', '')
    $store.commit('app/SET_CORPS', [])
    $store.commit('app/SET_PERMISSIONS', [])
    $store.commit('app/SET_DROPMENUS', [])
  }
}

/**
 * 设置公司
 */
function setCorpId(ctx, corpId) {
  let $cookies, $store
  // 客户端
  if (process.client) {
    $cookies = ctx.$cookies
    $store = ctx.store
  }
  // 服务端
  if (process.server) {
    $cookies = ctx.app.$cookies
    $store = ctx.store
  }
  if ($cookies && $store) {
    const hours = 24
    const expires = new Date(new Date().setHours(new Date().getHours() + hours))
    const domain = getDomian(ctx.$config.mallUrl)
    $cookies.set('corpId', corpId, { expires, path: '/', secure: true, sameSite: 'None', domain })
    $store.commit('app/SET_CORPID', corpId)
  }
}

function removeCorpId(ctx) {
  let $cookies, $store
  // 客户端
  if (process.client) {
    $cookies = ctx.$cookies
    $store = ctx.store
  }
  // 服务端
  if (process.server) {
    $cookies = ctx.app.$cookies
    $store = ctx.store
  }
  const domain = getDomian(ctx.$config.mallUrl)
  $cookies.remove('corpId', { path: '/', domain })
  $cookies.remove('set-corp-select', { path: '/', domain })
  $store.commit('app/SET_CORPID', '')
}

function setCorpSelect(ctx) {
  const days = 7
  const expires = new Date(new Date().setDate(new Date().getDate() + days))
  const domain = getDomian(ctx.$config.mallUrl)
  ctx.$cookies.set('set-corp-select', 1, { expires, path: '/', secure: true, sameSite: 'None', domain })
}

function getCorpSelect(ctx) {
  console.log(ctx.$cookies.get('set-corp-select'))
  return ctx.$cookies.get('set-corp-select')
}

export { setAuthInfo, removeAuthInfo, setCorpId, removeCorpId, setCorpSelect, getCorpSelect }
