export const statusMap = new Map([
  [0, '停用'],
  [1, '正常']
])

export const memberStatus = new Map([
  [0, '买家会员'],
  [1, '卖家会员'],
  [2, '普通会员']
])

// 客服热线
export const HOT_LINE = '400-828-0830'

// 客服热线
export const C2M_HOT_LINE = '400-079-1776'

// 仓库最大数量
export const MAX_WAREHOUSE_NUM = 20

export const waterName = 'www.gangbao365.com'

export const JIN_MAO = '江苏金贸钢宝电子商务股份有限公司'

export const JIN_MAO_ID = '001001'

// 到期前一星期
export const temporaryTime = 7 * 24 * 60 * 60 * 1000

export const PREFIXS = ['usercenter', 'sellercenter', 'buyercenter', 'moneycenter', 'processcenter']

// 加工仓库ids
export const C2M_REPO_IDS = ['01', '238']

// 螺纹钢材
export const threadSteelCompanyID = '004445'

export const threadSteelName = '热轧带肋钢筋'
