export default ($axios) => ({
  login(param) {
    return $axios.$post('/businesscenter/memberLogin/login', param, {
      showMessage: false
    })
  },
  getUserInfo() {
    return $axios.$get('/businesscenter/memberLogin/getUserInfo', {
      showMessage: false
    })
  },
  // 登录公司
  choiceCorp(param) {
    return $axios.$post('/businesscenter/memberLogin/choiceCorp', param, {
      showMessage: false
    })
  },
  // 获取登录者的菜单权限
  getPermission(params) {
    return $axios.$get('/businesscenter/memberMenu/getUserMenu', { params })
  },
  logout() {
    return $axios.$post('/businesscenter/memberLogin/logout')
  },
  // 刷新Token
  refreshToken(params) {
    return $axios.$get('/businesscenter/memberLogin/refreshToken', { params })
  },
  // 用户当前登录(所有的)的公司-列表
  getCorOpt(params) {
    return $axios.$get('/businesscenter/memberLogin/getCorOpt', { params })
  },
  // 切换公司身份验证
  checkChangeCorpCode(param) {
    return $axios.$post('/businesscenter/memberLogin/checkCode', param)
  },
  // 登入后显示超提
  getOverDelivery() {
    return $axios.$get('/businesscenter/delivery/overDelivery')
  },
  // 公司信息不全
  getByCorpIdFileTemp() {
    return $axios.$get('/businesscenter/corpAuth/getByCorpIdFileTemp')
  },
  // 消息弹框
  getDialogList() {
    return $axios.$get('/businesscenter/tecwindow/isWindow')
  },
  // 登入后已经完成成品入库预约提货
  getc2mDelivery() {
    return $axios.$get('/businesscenter/buyercprocessorder/getAppointmentWarn')
  }
})
