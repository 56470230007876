import { Message } from 'element-ui'

const showMessage = Symbol('showMessage')

class GangbaoMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single)
  }

  warning(options, single = false) {
    this[showMessage]('warning', options, single)
  }

  info(options, single = false) {
    this[showMessage]('info', options, single)
  }

  error(options, single = true) {
    this[showMessage]('error', options, single)
  }

  [showMessage](type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (process.client) {
        if (document.getElementsByClassName('el-message').length === 0) {
          Message[type](options)
        }
      }
    } else {
      Message[type](options)
    }
  }
}

export default new GangbaoMessage()
