export function transformName(rawData, key, value) {
  const array = JSON.parse(JSON.stringify(rawData).replace(new RegExp(key, 'g'), 'key').replace(new RegExp(value, 'g'), 'value'))
  return array
}

export function transformArray(rawData) {
  const array = []
  rawData.forEach((res) => {
    array.push({
      key: res,
      value: res
    })
  })
  return array
}

export function renderArr(data, keyName) {
  const map = new Map()
  const firstArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  firstArr.forEach((first, findex, fArr) => {
    data.forEach((item, index, arr) => {
      if (item[keyName] == first && !map.has(item[keyName]) && item[keyName]) {
        map.set(
          item[keyName],
          arr.filter((a) => a[keyName] == item[keyName])
        )
      }
    })
  })
  map.set(
    '#',
    data.filter((a) => !a[keyName])
  )
  const arr = {}
  for (const [key, value] of map) {
    if (value.length) {
      arr[key] = [...value]
    }
  }
  return arr
}
