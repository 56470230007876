import { useContext } from '@nuxtjs/composition-api'
import { removeAuthInfo } from '@/utils/auth'

export function useLogout() {
  const context = useContext()
  const logoutAsync = async () => {
    const { statusCode } = await context.$api.auth.logout()
    if (statusCode == 200) {
      removeAuthInfo(context)
      setTimeout(() => {
        if (window.location.href.includes('bcs')) {
          window.location.href = '/'
        } else {
          window.location.reload()
        }
      })
    }
  }
  return {
    logoutAsync
  }
}
