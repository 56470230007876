import Vue from 'vue'
export default function ({ store }) {
  Vue.directive('tableDrag', {
    inserted(el) {
      el = el.getElementsByClassName('el-table__body-wrapper')[0]
      el.style.cursor = 'grab'
      el.onmousedown = function () {
        const gapX = event.clientX
        const startX = el.scrollLeft
        document.onmousemove = function (e) {
          const x = e.clientX - gapX
          el.scrollLeft = startX - x
          return false
        }
        document.onmouseup = function (e) {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    }
  })
}
