// 防抖函数
export const debounce = (function () {
  let timer = 0
  return function (callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()

export function flattenTree(tree = []) {
  return tree?.reduce((flatten, curr) => {
    flatten.push(curr)
    if (Array.isArray(curr.children) && curr.children.length) {
      flatten.push(...flattenTree(curr.children))
    }
    return flatten
  }, [])
}

export function toLowerCase(str) {
  return str.replace(/[A-Z]/g, ($1) => '_' + $1.toLowerCase())
}

export function arrayToMap(array) {
  const map = new Map()
  array.forEach((_) => {
    map.set(_.key, _.value)
  })
  return map
}
