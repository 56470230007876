import Vue from 'vue'
import { formatDate, formatNumber, toFixed, formatTime, filterPhone } from '@/utils/tool'

const filters = {
  formatDate,
  formatNumber,
  toFixed,
  formatTime,
  filterPhone
}
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
export default filters
