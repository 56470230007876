import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    scope: Object,
    render: Function
  },
  render() {
    return this.render(this.scope)
  }
})
